.tableFixedHeader {
  width: 100%;
}

.tableFixedHeader thead, .tableFixedHeader tbody {
  display: block;
}

.tableFixedHeader tbody {
  overflow-y: auto;    /* Trigger vertical scroll    */
  overflow-x: hidden;  /* Hide the horizontal scroll */
}

.tableFixedHeaderShortBody {
  height: 20rem;       /* Just for the demo          */
  max-height: 40rem;
}

.tableFixedHeaderMediumBody {
  height: 40rem;       /* Just for the demo          */
  max-height: 40rem;
}

.transactionListTable tbody td, .transactionListTable thead th {
  width: 25%;
}

.datetime {
  min-width: 100px;
}