/* The switch - the box around the slider */
.switch-container {
  width: 100%;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.switch-notext {
  position: relative;
  display: inline-flex;
  width: 60px;
  height: 34px;
}

.switch {
  position: relative;
  display: inline-flex;
  width: 60px;
  height: 34px;
}

.switch:before {
  position: absolute;
  content: attr(data-left);
  margin-top: -17px;
  left: -13rem;
  width: 12rem;
  height: 67px;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
}

.switch:after {
  position: absolute;
  content: attr(data-right);
  margin-top: -17px;
  margin-left: 67px;
  width: 11rem;
  height: 67px;
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: 500;
}

/* Hide default HTML checkbox */
.switch input {display:none;}
.switch-notext input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #aaaaaa;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:disabled + .slider {
  background-color: #aaaaaa;
}

input:checked + .slider {
  background-color: var(--microlog-red);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--microlog-red);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}