.topbar {
  background-color: #eaeaea;
  color: #bbb;
}

.navbar-brand {
  color: #2b2a28;
  font-weight: bold;
}

.campaignBanner {
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  height: 50px;
  width: 550px;
  left:0px;
  margin: 0px;
  padding: 0px;
  position: fixed;
  margin-left: 40%;
}

@media screen and (max-width: 767px) {
  .campaignBanner {
    display: none;
  }
}

.campaignBanner-en {
  background-image: url("/assets/images/banner/giftcard/banner-campaign-webshop-en.jpg");
}

.campaignBanner-fi {
  background-image: url("/assets/images/banner/giftcard/banner-campaign-webshop-fi.jpg");
}

.campaignBanner-no {
  background-image: url("/assets/images/banner/giftcard/banner-campaign-webshop-no.jpg");
}

.campaignBanner-se {
  background-image: url("/assets/images/banner/giftcard/banner-campaign-webshop-se.jpg");
}