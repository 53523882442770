/*
 * Style tweaks
 * --------------------------------------------------
 */
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}
body {
  padding-top: 140px;
}
footer {
  padding: 30px 0;
}

.sidebar-offcanvas {
  top: 0px;
}

#sidebar {
  overflow: auto;
}

#sidebar .list-group {
  display: block;
  overflow-y: auto;
  //top: 70px;
  //padding-top: 100px;
}

.list-group-sticky-offset {
  top: 40px;
}

#sidebar .list-group-item.active {
  color: var(--microlog-black);
  background-color: var(--sp-m5);
  border-color: var(--sp-m5);
  border-radius: 24px;
}


/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: 767px) {
  .row-offcanvas {
    position: relative;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
  }

  .row-offcanvas-right {
    right: 0;
  }

  .row-offcanvas-left {
    left: 0;
  }

  .row-offcanvas-right
  .sidebar-offcanvas {
    right: -100%; /* 12 columns */
  }

  .row-offcanvas-right.active
  .sidebar-offcanvas {
    right: -50%; /* 6 columns */
  }

  .row-offcanvas-left
  .sidebar-offcanvas {
    left: -100%; /* 12 columns */
  }

  .row-offcanvas-left.active
  .sidebar-offcanvas {
    left: -50%; /* 6 columns */
  }

  .row-offcanvas-right.active {
    right: 50%; /* 6 columns */
  }

  .row-offcanvas-left.active {
    left: 50%; /* 6 columns */
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 70%; /* 6 columns */
  }
}