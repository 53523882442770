
.badge-50p {
  width: 50%;
  max-width: 50%;
}

.badge-60p {
  width: 60%;
  max-width: 60%;
}

.badge-70p {
  width: 70%;
  max-width: 70%;
}

dl.error input, dl.error input:focus {
  //background-color: rgba(250, 20, 20, 0.5);
  border-color: #e21e22;
}

.dropdown-toggle-textwidth {
  width: 18rem;
  max-width: 18rem;
}

.input-text {
  width: 18rem;
  max-width: 18rem;
}

.input-text-40p {
  width: 40%;
  max-width: 40%;
}

.input-text-50p {
  width: 50%;
  max-width: 50%;
}

.input-text-70p {
  width: 70%;
  max-width: 70%;
}

.input-text-80p {
  width: 80%;
  max-width: 80%;
}

.input-text-90p {
  width: 90%;
  max-width: 90%;
}

.input-text-fullwidth {
  width: 100%;
  max-width: 100%;
}

.input-text-smaller {
  width: 14rem;
  max-width: 14rem;
}

.input-text-smallest {
  width: 10rem;
  max-width: 10rem;
}

.input-text-right {
  float: right;
  width: 18rem;
  max-width: 18rem;
}

@media screen and (max-width: 767px) {
  .dropdown-toggle-textwidth {
    width: 11rem;
    max-width: 11rem;
  }

  .input-text {
    width: 11rem;
    max-width: 11rem;
  }

  .input-text-smaller {
    width: 8rem;
    max-width: 8rem;
  }
  .input-text-smallest {
    width: 5rem;
    max-width: 5rem;
  }

  .input-text-right {
    width: 11rem;
    max-width: 11rem;
  }
}

.input-number {
  float: right;
  width: 118px;
  max-width: 118px;
}

.input-number-fullwidth {
  width: 100%;
  max-width: 100%;
}

.input-percent {
  float: right;
  width: 80px;
  max-width: 80px;
}

.input-child {
  margin-left:auto;
  margin-right:auto;

  width: 140px;
  max-width: 140px;
}

.dropdown-wider {
  min-width: 15rem;
  width: 20rem;
  //padding-top: 90px;
}

.dropdown-instance, .dropdown-subgroup {
  clear: both;
}

.dropdown-subinstance {
  //text-indent: 25px;
}

.dropdown-submenu {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  width: 95%;
}

.dropdown-submenu>.dropdown-menu {
  padding-top: 12px;
  padding-left: 5px;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu-link:hover {
  background-color: #e21e22;
  color: #eee;
}
