@import "_topbar.scss";
@import "_sidebar2.scss";
@import "_copyToClipboard.scss";
@import "_form.scss";
@import "_form-property.scss";
@import "_groups.scss";
@import "_dataTable.scss";
@import "_mailer.scss";
@import "_slider.scss";
@import "_bootstrapOverride.scss";
@import "_tableFixedHeader.scss";


html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  //padding-top: 60px;
  //padding-bottom: 40px;
  background: #F5F0F0;
  //font-family: panton;
  //background-color: #faa;
  //z-index:0;
}

#indexContent{
  margin-bottom: 40px;
}

.container, .row.justify-content-center.align-items-center {
  height: 100%;
  min-height: 100%;
}

.container{
  padding-top: 60px;
}

// Custom scrollbar. Deactivate for now...

::-webkit-scrollbar { width: 10px; height: 15px;}
::-webkit-scrollbar-button {  background-color: #ddd; }
::-webkit-scrollbar-track {  background-color: #ddd;}
::-webkit-scrollbar-track-piece { background-color: #fff;}
::-webkit-scrollbar-thumb { height: 20px; background-color: #ddd; border-radius: 3px;}
::-webkit-scrollbar-corner { background-color: #ddd;}
::-webkit-resizer { background-color: #ddd;}


#loading-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  color:var(--microlog-red);
  z-index: 9999;
  display:none
}

#container-fluid {
  background-color: var(--microlog-red);
  z-index: 1;
}

.main {
  padding-top: 5%;
}

.dropdown-menu-absolute {
  position: absolute;
  max-height: 50rem;
  overflow-y: auto;
  left: -10rem;
}

.under{
  position: absolute;
  top: 100%;
}

.relative{
  position: relative;
}

.dropdown-menu-user {
  left: -5rem;
}

.groupsContent {
  margin: 0px auto;
  float: none;
}

.userEditReportSetup-sm {
  width: 95%;
  height: 500px;
  overflow-x: auto;
  overflow-y: hidden;
}

.userEditReportSetup-lg {
  width: 45em;
  height: 500px;
  overflow: scroll;
}

.mainInstanceLine {
  cursor: pointer;
}

.fa {
  color:var(--microlog-red);
}



.color-black {
  color: #000000;
}

.color-green {
  color: #66aa66;
}

.color-red {
  color: var(--microlog-red);
}

.color-redBackground {
  color: #ffffff;
  background-color: var(--microlog-red);
}

.color-white {
  color: #ffffff;
}

.text-strikethrough {
  text-decoration: line-through;
}

.flag-list {
  left: -1rem;
  min-width: 4rem;
  width: 4rem;
  text-align: center;
}

.flag {
  width: 32px;
  height: 22px;
  margin-left: auto;
  margin-right: auto;
}

.flag-selected {
  width: 40px;
  height: 28px;
  margin-left: auto;
  margin-right: auto;
}

.table-center {
  width: 90%;
  margin: 0px auto;
  float: none;
}

.table-borderless td,
.table-borderless th {
  border: 0;
}

.table-md {
  width: 70%;
  max-width: 50%;
  margin: 0px auto;
}

.table-fullwidth-wrap {
  width: 100%;
  max-width: 100%;
}
.table-fullwidth-wrap td {
  white-space: normal !important;
  word-wrap: break-word;
}
.table-scrollable {
  display: block;
  overflow: auto;
  white-space: nowrap;
}


.div-center {
  margin-left: auto;
  margin-right: auto;
}

.verticalCenter {
  //margin: auto;
}

.verticalText {
  width:5px;
  max-width: 5px;
  word-wrap: break-word;
  letter-spacing: 12px;
}


.password-popover {
  color: #000000;
  min-width: 20em !important;
}

.password-popover.password-popover-valid{
  color: #66aa66;
  font-size: 1rem;
  font-weight: bold;
}

.letter-spacing {
  letter-spacing: 2px;
}

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}
.placeholders h4 {
  margin-bottom: 0;
}
.placeholder {
  margin-bottom: 20px;
}
.placeholder img {
  display: inline-block;
  border-radius: 50%;
}

.modal-lg-with-scroll {
  overflow: scroll;
}

.transparentTransitionContainer {
  display: grid;
}

.transparentTransition {
  grid-row: 1;
  grid-column: 1;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.transparentTransition.transparent {
  opacity:0;
}


.fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.checkbox {
  padding-left: 20px;
}

.checkbox-success{
  background-color: #5cb85c;
}

#webshopWhitelistModalContentCheckmark {
  display:inline-block;
  width: 22px;
  height:22px;
  background: #66aa66;
  border-radius:50%;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

#webshopWhitelistModalContentCheckmark:before{
  content:"";
  position: absolute;
  width:3px;
  height:9px;
  background-color:#fff;
  left:11px;
  top:6px;
}

#webshopWhitelistModalContentCheckmark:after{
  content:"";
  position: absolute;
  width:3px;
  height:3px;
  background-color:#fff;
  left:8px;
  top:12px;
}

.margin-left-whitelist{
  margin-left: 440px;
}

.margin-left-bankinfo{
  margin-left: 75%;
}

.dropdown-menu-size{
  transform: translate3d(0px, 2.5rem, 0px) !important;
  min-height: 5rem;
  max-height: 55rem;
  height: auto;
  min-width: 15rem;
  width: 25rem;
  overflow: auto;
}

.dropdown-menu-size3{
  transform: translate3d(0px, 2.5rem, 0px) !important;
  min-height: 5rem;
  max-height: 20rem;
  height: auto;
  min-width: 15rem;
  width: 20rem;
  overflow: auto;
}

.font-georgia {
  font-family: "Noto Sans Georgian"
}

.auto-width{
  min-width: 15rem;
  width: 20rem;
}

.auto-heigth{
  height: auto;
}

.btn-resize{
  background-color: var(--microlog-red);
  color: white;
  width: 10rem;
  height: 3rem;
  flex: 1;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 100%;
  border-radius: 10px;
  border: none;
  z-index: 1;
}

.btn-resize:hover{
  background-color: var(--microlog-red);
}

.btn-resize2{
  background-color: var(--microlog-red);
  color: white;
  width: 15rem;
  height: 3rem;
  flex: 1;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 100%;
  border-radius: 10px;
  border: none;
}

.btn-resize2:hover{
  background-color: var(--microlog-red);
}

.frameworkBtnHover:hover {
  overflow: visible;
  z-index: 1;
  position: relative;
  width: 20rem;
}

.h-10rem{
  height: 10rem;
}


.onboardingCheckbox{
  accent-color: var(--microlog-red);
  width: 25px;
  height: 25px;
}

.w-10{
  width: 10%;
}

#onboardingFloatingInfo{
  position: fixed;
  left: 20rem;
  top: 2rem;
  width: 15%;
}

#sidebarPortal{
  //height: 100%;
  //width: 10%;
  //background-color: #FCFAFA;
  background-color: #000000;
}

.sidebarDiv{
  background-color: #F8EBE3;
  border-radius: 15%;
}

.z-1{
  z-index: 1;
}

.customDisabled{
  pointer-events: none;
  color: gray;
}

.confirmAvailable{
  color: green;
}

#regularAlertModal .modal-footer, #errorAlertModal .modal-footer {
  height: 4rem !important;
  min-height: 4rem;
}

.btn-outline-primary {
  &:focus {
    color: #fff;
  }
}

.btn-outline-primary-static {
  &:hover {
    background-color: transparent;
    color: var(--microlog-red);
  }
  &:focus {
    background-color: transparent ;
    color: var(--microlog-red);
    box-shadow: none;
  }
  &:active {
    background-color: transparent !important;
    color: var(--microlog-red) !important;
    box-shadow: none !important;
  }
}

.checkbox2rem{
  width: 2rem;
  height: 2rem;
}

.controlSchemaTrashcan:hover{
  color: var(--microlog-red);
}

.controlSchemaHover:hover{
  background-color: lightgray;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 25px;
}

.controlSchemaInputHover:hover{
  border-color: var(--microlog-red);
}
