:root{
  --sp-m5: #F8EBE3;
  --sp-4: #908989;
  --microlog-red: #CB0428;
  --microlog-black: #231E1E;
  --golos-font: "Golos Text", sans-serif;
}

span div p td{
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
}

a, a:focus, a:hover, .btn-link:focus, .btn-link:hover, .page-link:focus, .page-link:hover {
  color: var(--microlog-red);
}

.btn-outline-primary {
  //color: #e21e22;
  color: var(--microlog-red);;
  background-image: none;
  background-color: transparent;
  border-color: var(--microlog-red);;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--microlog-red);
  border-color: var(--microlog-red);
}

.btn-outline-primary:focus, .btn-outline-primary:active{
  border-color: var(--microlog-red);;
  background-color: var(--microlog-red);;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--microlog-red);;
  border-color: var(--microlog-red);;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--microlog-red);;
}

.btn-primary {
  color: #eee;
  background-color: var(--microlog-red);
  border-color: var(--microlog-red);
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--microlog-red);
  border-color: var(--microlog-red);
}

.btn-primary:disabled {
  //color: #fff;
  background-color: #cbcbcb;
  border-color: #b7b7b7;
}

.btn-outline-primary:disabled {
  color: #b7b7b7;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 3px var(--microlog-red);
}

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--microlog-red);
  background-image: none;
  border-color: var(--microlog-red);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--microlog-red);
  border-color: var(--microlog-red);
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--microlog-red);
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--microlog-red);
}

.border-gray {
  border: #ced4da solid 1px;
}

.dropdown-item {
  padding: 0rem 1.5rem;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--microlog-red);
}

.dropdown-toggle {
  color: var(--microlog-red);
  clear:both;
  display: block;
  min-width: 95%;
}

.dropdown-toggle-fullwidth {
  min-width: 100%;
}

.dropdown-menu {
  position: relative;
  width: 95%;
}

.dropdown-menu a, .dropdown-submenu a {
  display: inline-table;
  width: 95%;
}

.dropdown-menu a:hover, .dropdown-submenu a:hover, .dropdown-submenu h5:hover, .dropdown-submenu h6:hover, .dropdown-menu li:hover {
  background-color: var(--microlog-red);
  color: #eee;
  text-decoration: none;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: var(--microlog-red);
}

.form-control {
  height: unset;
}

.form-control:focus {
  border-color: var(--microlog-red);
  outline: 0;
  box-shadow: 0 0 0 0.03rem rgba(255, 10, 10, 0.5);
}



.table td, .table th {
  padding: 0.35rem;
}

.table-striped, .table-hover {
  border-color: #ddd;
  border-width: 1px;
  border-style: solid;
}

.table-striped thead, .table-hover thead {
  //background: #e3001A;
  background: #fff;
  color: var(--microlog-red);
  margin-top: 0px;
  padding-top: 0px;
}

.table-striped tfoot, .table-hover tfoot {
  background: #fff;
  color: #000;
  margin-top: 0px;
  padding-top: 0px;
}

// **********
// Carousel
// **********
.carousel-fade .carousel-item {
  transition: transform 2s ease, opacity .5s ease-out
}
.carousel-inner {
}

.carousel-indicators {
  //bottom: 15px;
  justify-content: left;
  z-index: 0;
  margin-left: 10px;
}

.carousel-indicators::after {
  margin-top: 10px;
  margin-left: -165px;
  color: var(--microlog-red);
  content: attr(data-content);
}

.carousel-indicators li {
  width: 50px;
  height: 4px;
  background-color: #aaaaaa;
  cursor: default;
}

.carousel-indicators .active {
  background-color: var(--microlog-red);
}

// ***********
// Modal
// ***********
.modal-footer {
  min-height: 5rem;
  background-color: #eee;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Golos Text", sans-serif !important;
}

.golos{
  font-family: "Golos Text", sans-serif !important;
}

.healthDashboardHeader{
  font-family: 'Golos Text', sans-serif !important;
  font-size: 40px;
  font-weight: normal;
}

.dashboardGrey{
  font-family: 'Golos Text', sans-serif;
  color: #ABA5A5;
  font-size: 32px;
}

.dashboardSierra{
  font-family: 'Golos Text', sans-serif;
  color: #510011;
  font-size: 32px;
}

select.form-select option:hover{
  background-color: var(--microlog-red);
  //border-color: var(--microlog-red);
}

form-select option:hover{
  background-color: var(--microlog-red);
  //border-color: var(--microlog-red);
}

.p-left{
  padding-left: 4rem;
}

.info-blue{
  background-color: #CFF4FC !important;
}

.dbHover:hover{
  //border-color: var(--sp-m5);
  //border: 5px solid var(--sp-m5);
  background-color: var(--sp-m5);
}

.indexPageIndicator{
  background-color: transparent;
  border: transparent;
}

.indexPageIndicator:active{
  background-color: var(--sp-m5);
}

.noScroll{
  overflow-x: hidden;
}

.borderTEST{
  border: 2px var(--microlog-red);
  background-color: var(--microlog-red);
}

.merchantSelectorBtn{
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis ;
}

.full-height{
  min-height: 100%;
}