.form-property {
  margin-left:auto;
  margin-right:auto;
  width: 100%;
}

.table-property {
  margin-left:auto;
  margin-right:auto;
  width: 100%;
}

.table-property .td-input {
  text-align: center;
  //width:50%;
}

.dropdown-groupInstanceSelector {
  float: right;
  width: 18rem;
  max-width: 18rem;
}

.card-child {
  float: right;
  width: 18rem;
  max-width: 18rem;
}

@media screen and (max-width: 767px) {
  .dropdown-groupInstanceSelector {
    width: 11rem;
    max-width: 11rem;
  }

  .card-child {
    width: 11rem;
    max-width: 11rem;
  }

}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(226, 30, 34, 0.5);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: rgba(226, 30, 34, 0.5);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
  margin-left:auto;
  margin-right:auto;
  background-color: #e21e22;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e21e22;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #e21e22;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(226, 30, 34, 0.5);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='red'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(226, 30, 34, 0.5);
}

.custom-select:focus {
  border-color: #e21e22;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(226, 30, 34, 0.5);
}

.custom-checkbox-lg {
  float: right;
  width: 200px;
  max-width: 200px;
}

.custom-checkbox-sm {
  margin: 0px auto;
}