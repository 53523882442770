.btn-header-lg {
  width: 5em;
}

.btn-header-md {
  width: 3em;
}

.groupsSubGroupsInputPrepend {
  min-width: 35%;
}
.groupsSubGroupsInputPrepend > span {
  width: 100%;
}
.groupsSubGroupsInputPrependLarge {
  //min-width: 35%;
  min-width: 17.4%;
}
.groupsSubGroupsInputPrependLarge > span {
  width: 100%;
}